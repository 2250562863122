import { graphql } from "gatsby";
import React from "react";
import Layout from "~/components/Layout";

import FlexibleContent from "~/components/FlexibleContent";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";
import Resources from "~/components/flexible/Resources";
import SearchResults from "~/components/global/SearchResults";
import useBlogPosts from "~/hooks/useBlogPosts";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { Section } from "../components/elements/Section";
import { ArticleAlt } from "../components/flexible/Resources";

export default function categoryPage({
  data: {
    wpPage,
    wp,
    wpCategory,
    allWpPost,
  },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {

  return (
    <Layout wp={wp}>
      <Seo post={wpCategory} title={wpCategory.name} />
      <Hero  {...wpCategory}  />
      <Articles articles={allWpPost} />
    </Layout>
  );
}


export const Hero = ({ name }) => {
  return (
    <Section layoutName={'CategoryHero'}>
      <div className="container ">
        <div className="space-y-8">
          <h1 className="text-h1-large max-w-[620px] mx-auto text-center" dangerouslySetInnerHTML={{ __html: name }} />
        </div>
      </div>
    </Section>
  )
}

export const Articles = ({ articles }) => {
  return (
    <Section layoutName={'CategoryArticles'}>
      <div className="container">
        <div className="max-w-[995px] mx-auto">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-16">
            {articles?.nodes?.map((article, i) => (
              <ArticleAlt {...article} />
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}



export const pageQuery = graphql`
  query Category($id: String!, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
    wpCategory(id: { eq: $id }) {
      ...GeneratedWpCategory
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        uri
        title
        article {
          image {
            ...Image
          }
          summary
          readTime
        }
        featuredImage {
          node {
            ... Image
          }
        }
      }
    }
  }
`;
